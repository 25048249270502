<template>
  <b-card>
    <ol
      class="c6 lst-kix_list_7-1 start"
      start="1"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.4i7ojhp"
          style="display:inline"
        >
          <span class="c1">Tambah Surat Keluar (Staff SE).</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 317.66px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image17.png')"
          style="width: 151.18px; height: 317.66px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c5">
      <span class="c4">Untuk mengakses menu </span><span class="c11 c8">Tulis Surat</span><span class="c4">, user harus memilih menu </span><span class="c11 c8">Tulis Surat</span><span class="c4">&nbsp;yang ada pada bilah sidebar yang berada pada sisi kiri layar, lalu user akan diarahkan ke form untuk mengisi semua informasi yang dibutuuhkan untuk </span><span class="c11 c8">Surat Keluar </span><span class="c4">seperti yang ditampilkan pada gambar dibawah ini:</span>
    </p>
    <p class="c19 c12">
      <span class="c4">&nbsp;</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 740.40px; height: 324.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image1.png')"
          style="width: 740.40px; height: 324.67px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Pada </span><span class="c8">form surat keluar</span><span class="c4">&nbsp;diatas, setelah user mengisi semua informasi yang dibutuhkan, langkah selanjutnya yaitu mengklik tombol simpan yang ditunjukkan seperti pada kotak berwarna merah diatas.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="2"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.2xcytpi"
          style="display:inline"
        >
          <span class="c1">Daftar Surat Keluar (Pihak Terkait Surat Keluar)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <a id="t.c6a1ee4b01f107f10a4cb40277981d9a02b46bae" /><a id="t.6" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c42"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 318.83px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image2.png')"
                style="width: 151.18px; height: 318.83px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
        <td
          class="c54"
          colspan="1"
          rowspan="1"
        >
          <p class="c41">
            <span>Pilih </span><span class="c8">Proses Pengajuan</span><span>&nbsp;atau </span><span class="c8">Daftar Surat Keluar</span>
          </p>
        </td>
        <td
          class="c43"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 318.83px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image3.png')"
                style="width: 151.18px; height: 318.83px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c10">
      <span>Untuk melihat daftar Surat Keluar yang telah dibuat sebelumnya, user bisa mengaksesnya dengan memilih menu </span><span class="c8">Proses Pengajuan</span><span>&nbsp;atau </span><span class="c8">Daftar Surat Keluar </span><span>dimana user akan di arahkan kepada detail surat keluar berdasarkan pada status surat keluar dimana status open adalah proses pengajuan dan </span><span class="c8">status close</span><span>&nbsp;adalah daftar </span><span class="c8">surat keluar. </span><span class="c4">setelah salah satu menu diatas dipilih maka akan muncul tampilan seperti berikut dibawah.</span>
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 339.75px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image4.png')"
          style="width: 709.80px; height: 395.99px; margin-left: -0.00px; margin-top: -51.38px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="3"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.1ci93xb"
          style="display:inline"
        >
          <span class="c1">Detail Surat Keluar (Pihak Terkait Surat Keluar)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span>Setelah kita melihat daftar Surat Keluar seperti pada tahap sebelumnya baik daftar </span><span class="c8">Proses Pengajuan</span><span>&nbsp;maupun </span><span class="c8">Daftar Surat Keluar</span><span class="c4">, maka kita bisa memilih surat keluar mana yang akan dilihat detail datanya, dengan meng-klik pada bagian kolom No. Surat Keluar yaitu pada nomor surat keluar yang digaris bawahi yang ingin dipilih.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 322.30px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image5.png')"
          style="width: 709.80px; height: 322.30px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c5">
      <span>Setelah salah satu nomor surat tersebut di-klik, maka pengguna akan diarahkan ke halaman </span><span class="c8">Detail Surat Masuk</span><span class="c4">, pada halaman tersebut kita bisa melihat detail dari disposisi yang sudah ditambahkan sebelumnya.</span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 257.69px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image6.png')"
          style="width: 709.80px; height: 257.69px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 202.85px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image7.png')"
          style="width: 709.80px; height: 202.85px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span class="c4">Pada halaman ini juga user bisa melihat file surat keluar yang telah diupload sebelumnya.</span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="4"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.3whwml4"
          style="display:inline"
        >
          <span class="c1">Edit Surat Keluar (Staff SE)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Jika diperlukan pengubahan data pada dokumen surat keluar, user bisa mengakses menu edit surat keluar baik melalui daftar </span><span class="c8">Proses Pengajuan</span><span>&nbsp;maupun </span><span class="c8">Daftar Surat Keluar, </span><span class="c4">dengan langkah-langkah sebagai berikut.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <h3
      id="h.2bn6wsx"
      class="c39"
    >
      <span class="c1">3.4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Akses Menu Edit Melalui Daftar Surat Keluar (Staff SE)</span>
    </h3>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 180.36px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image8.png')"
          style="width: 709.80px; height: 180.36px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c19 c24">
      <span class="c4">Edit Melalui Daftar Perintah bisa dilakukan dengan langkah-langkah sebagai berikut:</span>
    </p>
    <p class="c19 c24">
      <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mengarahkan pointer mouse kearah kanan dan meng-klik symbol </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 19.50px; height: 18.19px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 1563.93px; height: 649.66px; margin-left: -1481.21px; margin-top: -557.84px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau titik-titik vertical. </span>
    </p>
    <p class="c18 c24">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lalu mengklik dilanjutkan dengan meng-klik perintah </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 63.65px; height: 25.05px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 856.00px; height: 355.13px; margin-left: -779.43px; margin-top: -277.54px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau edit.</span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <h3
      id="h.qsh70q"
      class="c39"
    >
      <span class="c1">3.4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Akses Menu Edit Melalui Halaman Detail Surat Keluar (Staff SE).</span>
    </h3>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c10">
      <span class="c4">Jika anda sedang berapa pada halaman detail surta keluar dan anda telah login sebagai staff SE, maka anda bisa mengakses fitur edit surat keluar dengan mengarahkan pointer mouse kearah pojok kanan atas form tulis surat keluar lalu mign-kliknya, seperti yang ditunjukkan pada kotak merah pada gambar dibawah ini.</span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 257.75px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image38.png')"
          style="width: 709.80px; height: 257.75px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <h3
      id="h.3as4poj"
      class="c39"
    >
      <span class="c1">3.4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Menyimpan dan Validasi Surat Keluar Setelah Melakukan Edit Data (Staff SE).</span>
    </h3>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Setelah user selesai melakukan semua pengeditan data Surat Keluar yang diperlukan, langkah selanjutnya yaiutu melakukan penyimpanan data yang telah diubah, jika tidak dilakukan maka data yang telah diubah tidak akan tersimpan, pada tahap ini juga pengguna yang mengedit juga harus menyertakan </span><span class="c8">File Validasi Surat</span><span>&nbsp;lalu </span><span class="c8">pengubahan status</span><span>&nbsp;dan </span><span class="c8">memberi catatan penting</span><span>&nbsp;pada seksi form </span><span class="c8">validasi surat keluar</span><span class="c4">, setelah itu meng-klik tombol simpan yang ditunjukkan pada kotak berwarna merah seperti dibawah ini.</span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <a id="t.5e745051cc9add19d2c4d643a4f67c235843949b" /><a id="t.7" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c56"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 256.99px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image41.png')"
                style="width: 709.80px; height: 256.99px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
          <p class="c17 c13">
            <span class="c4" />
          </p>
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 199.34px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image42.png')"
                style="width: 709.80px; height: 199.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
